import React, { useEffect } from 'react';

import { navigate } from 'gatsby';
import Layout from 'components/Layout';
import EventCardAnimation from 'components/EventCardAnimation';

function Callback() {
  useEffect(() => {
    navigate('/');
  }, []);
  return (
    <Layout>
      <EventCardAnimation />
    </Layout>
  );
}

export default Callback;
